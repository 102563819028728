import { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { ResetAPIStatus } from '../actions/app';
import useSnackbar from '../utils/use-snackbar';
import { getAPIStatus } from '../selectors/app';
import NotificationSnackbar from '../components/notification-snackbar';
import PublicLayout from '../layouts/public';
import { SentryRoutes } from '../utils/sentry';

const AdminLayout = lazy(() => import('../layouts/admin'));

const App = () => {
    const dispatch = useDispatch();
    const apiStatus = useSelector(getAPIStatus);
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();
    useEffect(() => {
        if (apiStatus.completed) {
            if (apiStatus.error && apiStatus.errorMsg) handleOpenSnackbar({ text: apiStatus.errorMsg, type: 'error', autoHideDisabled: apiStatus.autoHideDisabled });
            else if (!apiStatus.error && apiStatus.successMsg) handleOpenSnackbar({ text: apiStatus.successMsg, type: 'success', autoHideDisabled: apiStatus.autoHideDisabled });
            dispatch(ResetAPIStatus());
        }
    }, [apiStatus, handleOpenSnackbar, ResetAPIStatus]);
    return (
        <>
            <NotificationSnackbar {...snackbarProps} />
            <SentryRoutes>
                <Route path="/admin/*" element={<AdminLayout />} />
                <Route path="/*" element={<PublicLayout />} />
            </SentryRoutes>
        </>
    );
};

export default App;
