import AppTypes from '../action-types/app';

export const ResetAPIStatus = () => ({
    type: AppTypes.API_RESET,
});

export const APIError = (text = null, autoHideDisabled = null) => ({
    type: AppTypes.API_ERROR,
    payload: {
        text,
        autoHideDisabled,
    },
});

export const APISuccess = (text = null, autoHideDisabled = null) => ({
    type: AppTypes.API_SUCCESS,
    payload: {
        text,
        autoHideDisabled,
    },
});
