import IntakeTypes from '../action-types/intake';

const internals = {
    initial: () => ({
        values: {
            firstName: '',
            lastName: '',
            phone: '',
            dob: '',
            hasFelonyCase: false,
            selectedCase: '',
        },
        status: {
            active: false,
        },
        caseResults: [],
    }),
};

const IntakeReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case IntakeTypes.INTAKE_UPDATE:
        return {
            ...state,
            values: {
                ...state.values,
                ...payload,
            },
        };
    case IntakeTypes.INTAKE_UPDATE_CASE_RESULTS:
        return {
            ...state,
            caseResults: payload,
        };
    case IntakeTypes.INTAKE_UPDATE_STATUS:
        return {
            ...state,
            status: {
                ...payload,
            },
        };
    case IntakeTypes.INTAKE_UPDATE_VALUES:
        return {
            ...state,
            values: {
                ...state.values,
                ...payload,
            },
        };

    case IntakeTypes.INTAKE_CLEAR:

        return internals.initial();

    default:
        // do nothing
    }
    return state;
};

export default IntakeReducer;
