const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    INTAKE_UPDATE: true,
    INTAKE_UPDATE_CASE_RESULTS: true,
    INTAKE_UPDATE_VALUES: true,
    INTAKE_UPDATE_STATUS: true,
    INTAKE_CLEAR: true,
    INTAKE_SUBMIT_BEGIN: true,
    INTAKE_SUBMIT_SUCCESS: true,
    INTAKE_SUBMIT_ERROR: true,
});
