import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import useMediaQuery from '@mui/material/useMediaQuery';

import Colors from '../../../styles/colors';
import PrimaryButton from '../../../components/buttons/primary-button';

const StyledButton = styled(Button)(() => ({
    fontSize: 16,
    color: 'black !important',
    height: 58,
    marginLeft: 15,
    marginRight: 15,
    borderRadius: 0,
    '&:hover': {
        backgroundColor: 'transparent',
        borderBottom: `4px solid ${Colors.purple1}`,
        marginBottom: -4,
    },
}));

export default () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    if (isMobile) {
        return (
            <header style={{ backgroundColor: 'rgb(255, 251, 255)' }}>
                <Container>
                    <Grid xs={12} container alignItems="center" justifyContent="space-between" direction="column">
                        <Grid item xs>
                            <a
                                href="https://www.unbail.org/"
                                type="button"
                                alt="home"
                            >
                                <img src="https://assets-global.website-files.com/6567a5684dc688ad9c29f0d7/65739c753ab42cbdabb646be_Vectors-Wrapper.svg" alt="" />
                            </a>
                        </Grid>
                    </Grid>
                </Container>
            </header>
        );
    }

    return (
        <header style={{ height: 58, backgroundColor: 'rgb(255, 251, 255)' }}>
            <Container>
                <Grid xs={12} item container alignItems="center" justifyContent="space-between">
                    <Grid item xs>
                        <Button
                            variant="outlined"
                            href="https://www.unbail.org/donate"
                            style={{
                                height: 38,
                                borderRadius: 12,
                                color: 'black',
                                borderColor: 'black',
                                marginRight: 15,
                            }}
                            sx={{
                                display: { xs: 'none', lg: 'inline-flex' },
                            }}
                        >
                            Donate
                        </Button>
                        <StyledButton href="https://www.unbail.org/courtchat" alt="Court Chart" type="button">
                            CourtChat
                        </StyledButton>
                        <StyledButton href="https://www.unbail.org/about-us" alt="About Us" type="button">
                            About Us
                        </StyledButton>
                    </Grid>
                    <Grid item xs="auto">
                        <button
                            href="https://www.unbail.org/"
                            type="button"
                            alt="home"
                        >
                            <img src="https://assets-global.website-files.com/6567a5684dc688ad9c29f0d7/65739c753ab42cbdabb646be_Vectors-Wrapper.svg" alt="" />
                        </button>
                    </Grid>
                    <Grid
                        xs
                        item
                        style={{ textAlign: 'right' }}
                        sx={{
                            display: { xs: 'none', lg: 'block' },
                        }}
                    >
                        <PrimaryButton
                            href="https://www.unbail.org/"
                            style={{
                                color: 'white',
                                height: 38,
                                borderRadius: 12,
                            }}
                        >
                            Sign Up
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Container>
        </header>
    );
};
