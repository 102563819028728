import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { ThemeProvider, styled } from '@mui/material/styles';

import TertiaryButton from '../buttons/tertiary-button';

import themer from '../../styles/material-theme';
import styles from './styles';

import ErrorImg from './images/500-error@2x.png';

const ErrorContent = styled('div')(styles.root);
const Description = styled('div')(styles.description);

const ErrorBoundary = () => {
    const navigate = useNavigate();

    const handleBackToHomeClick = () => {
        navigate('/');
    };

    // this component uses the pattern of migrating from JSS to a styled component
    // as outlined in https://mui.com/guides/migration-v4/#migrate-from-jss
    return (
        <ThemeProvider theme={themer}>
            <main>
                <ErrorContent className="container">
                    <Typography variant="h1" sx={styles.title}>
                        Oops!
                    </Typography>

                    <Description>
                        Something went wrong on our end.
                    </Description>

                    <Description>
                        Please try again.
                    </Description>

                    <TertiaryButton
                        className="mt-4 mb-3"
                        onClick={handleBackToHomeClick}
                    >
                        Back to Home
                    </TertiaryButton>

                    <div style={styles.iconContainer}>
                        <img src={ErrorImg} alt="" style={styles.icon} />
                    </div>
                </ErrorContent>
            </main>
        </ThemeProvider>
    );
};

export default ErrorBoundary;
