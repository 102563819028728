export default {
    purple1: '#6938DF', // primary color
    purple2: '#4F2B9B', // light purple
    purple3: '#E8DDFF', // light purple
    purple4: '#F5EEFF', // light purple
    purple5: '#fdf6fe', // background purple
    deepPurple: '#1C1B3A', // fonts

    grey1: '#48464a', // grey
    grey2: '#605D62', // light grey

    error: '#e26875',
    success: '#02FF7F',
    successRGB: '2, 255, 127',
};
