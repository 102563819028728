import WebClient from '../utils/web-client';
import AuthTypes from '../action-types/auth';
import UserTypes from '../action-types/user';

export const ClearErrors = () => ({
    type: AuthTypes.INITIALIZE_APP,
});

export const LoginBegin = () => ({
    type: AuthTypes.LOGIN_BEGIN,
});
const LoginSuccess = (token, role) => ({
    type: AuthTypes.LOGIN_SUCCESS,
    payload: {
        token,
        role,
    },
});
const LoginError = (errorMsg) => ({
    type: AuthTypes.LOGIN_ERROR,
    payload: { errorMsg },
});
export const SetupUser = (user) => ({
    type: UserTypes.SETUP_USER,
    payload: user,
});

export const Login = (formValues, onSuccess, onError) => async (dispatch) => {
    const { email, password } = formValues;

    dispatch(LoginBegin());
    try {
        const { data } = await WebClient.post(
            '/login',
            { email, password },
        );

        // update auth token
        WebClient.updateAuth(data.token);

        dispatch(SetupUser(data.user));
        dispatch(LoginSuccess(data.token, data.user.role));
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';
        if (error.response && (error.response.status === 401)) {
            errorType = 'UNAUTHORIZED';
        }
        if (error.response && (error.response.status === 422)) {
            errorType = 'UNPROCESSABLE';
        }
        dispatch(LoginError(errorType));
        if (onError) onError(errorType);
    }
};

const LogoutInterals = () => ({
    type: AuthTypes.LOGOUT,
});

export const Logout = () => async (dispatch) => {
    try {
        await WebClient.post('/logout');
        dispatch(LogoutInterals());
    } catch (error) {
        dispatch(LogoutInterals());
    }
};

export const ForgotPassBegin = () => ({
    type: AuthTypes.FORGOT_PASSWORD_BEGIN,
});
const ForgotPassSuccess = () => ({
    type: AuthTypes.FORGOT_PASSWORD_SUCCESS,
});
const ForgotPassError = (errorMsg) => ({
    type: AuthTypes.FORGOT_PASSWORD_ERROR,
    payload: { errorMsg },
});
export const ForgotPass = (email, onSuccess, onError) => async (dispatch) => {
    dispatch(ForgotPassBegin());

    try {
        await WebClient.post('/users/request-reset', { email });
        dispatch(ForgotPassSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (
            error.response.status === 404
                || error.response.status === 400
        )) {
            errorType = 'NOT_FOUND';
        }

        dispatch(ForgotPassError(errorType));
        if (onError) onError(errorType);
    }
};

export const ResetPassBegin = () => ({
    type: AuthTypes.RESET_PASSWORD_BEGIN,
});
const ResetPassSuccess = () => ({
    type: AuthTypes.RESET_PASSWORD_SUCCESS,
});
const ResetPassError = (errorMsg) => ({
    type: AuthTypes.RESET_PASSWORD_ERROR,
    payload: { errorMsg },
});
export const ResetPass = (formValues, onSuccess, onError) => async (dispatch) => {
    dispatch(ResetPassBegin());

    const { email, resetToken, password } = formValues;

    try {
        await WebClient.post('/users/reset-password', {
            email,
            newPassword: password,
            resetToken,
        });
        dispatch(ResetPassSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (
            error.response.error === 404
                || error.response.error === 400
        )) {
            errorType = 'NOT_FOUND';
        }

        dispatch(ResetPassError(errorType));
        if (onError) onError(errorType);
    }
};

const CheckTokenBegin = () => ({
    type: AuthTypes.CHECK_TOKEN_BEGIN,
});
const CheckTokenSuccess = () => ({
    type: AuthTypes.CHECK_TOKEN_SUCCESS,
});
const CheckTokenError = () => ({
    type: AuthTypes.CHECK_TOKEN_ERROR,
});

export const CheckToken = () => async (dispatch) => {
    dispatch(CheckTokenBegin());

    try {
        await WebClient.get('/users/authenticated');
        dispatch(CheckTokenSuccess());
    } catch (error) {
        dispatch(CheckTokenError());
        dispatch(Logout());
    }
};
