import AdminTypes from '../action-types/admin';

const internals = {
    initial: () => ({
        categories: [],
    }),
};

const AdminReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case AdminTypes.GET_CATEGORIES_SUCCESS:
        // take the payload and turn it into an array of objects
        // eslint-disable-next-line no-case-declarations
        const categoryArray = Object.keys(payload).map((key) => {
            const mutatedFields = {};
            Object.keys(payload[key].fields).forEach((fieldKey) => {
                mutatedFields[payload[key].fields[fieldKey].name] = {
                    key: payload[key].fields[fieldKey].name,
                    ...payload[key].fields[fieldKey],
                };
            });
            return ({
                key,
                name: payload[key].name,
                // loop through the field object, taking each key and adding to the object as a key param
                fields: mutatedFields,
            });
        });

        return {
            ...state,
            categories: categoryArray,
        };

    default:
        // do nothing
    }
    return state;
};

export default AdminReducer;
