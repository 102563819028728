import { combineReducers } from 'redux';
import Auth from './auth';
import User from './user';
import Intake from './intake';
import Admin from './admin';
import App from './app';

const Reducers = () => combineReducers({
    auth: Auth,
    user: User,
    intake: Intake,
    admin: Admin,
    app: App,
});

export default Reducers;
