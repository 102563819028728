import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import noop from '../../../utils/noop';

const PrimaryButton = ({
    children,
    onClick,
    disabled,
    disabledMessage,
    ...props
}) => {
    const [isDisabledMessageOpen, setDisabledMessageOpen] = useState(false);

    const handleClick = (event) => {
        if (disabledMessage && disabled) {
            setDisabledMessageOpen(true);
        } else {
            onClick(event);
        }
    };

    const handleDisabledMessageClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setDisabledMessageOpen(false);
    };

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                disableElevation
                disableFocusRipple
                disableRipple
                disabled={disabled}
                onClick={handleClick}
                aria-disabled={disabled}
                // disabled={disabled} // this will disable the snackbar functionality
                {...props} // eslint-disable-line react/jsx-props-no-spreading
            >
                { children }
            </Button>

            <Snackbar
                open={isDisabledMessageOpen}
                autoHideDuration={6000}
                onClose={handleDisabledMessageClose}
                message={disabledMessage}
                action={(
                    <IconButton size="small" aria-hidden color="inherit" onClick={handleDisabledMessageClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                )}
            />
        </>
    );
};

PrimaryButton.defaultProps = {
    children: null,
    disabled: false,
    onClick: noop,
    disabledMessage: null,
};

PrimaryButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    disabledMessage: PropTypes.string,
};

export default PrimaryButton;
