import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GA4React from 'ga-4-react';

let ga4React = null;

export const useGA4 = () => {
    if (ga4React) {
        return ga4React;
    }

    if (process.env.REACT_APP_GA4) {
        ga4React = new GA4React(process.env.REACT_APP_GA4);
        ga4React.initialize().then(() => { ga4React.isInitialized = true; });
        return ga4React;
    }

    return null;
};

const GoogleAnalytics = () => {
    const ga = useGA4();
    const location = useLocation();

    useEffect(() => {
        if (ga && ga.isInitialized) {
            ga.pageview(location.pathname, location.search, location.pathname);
        }
    }, [location]);

    return null;
};

export default GoogleAnalytics;
