import Axios from 'axios';

const internals = {
    host: process.env.REACT_APP_API_HOST,
    prefix: process.env.REACT_APP_API_PREFIX,
    getApiBase: () => {
        const { host, prefix } = internals;
        if (!prefix) {
            return host;
        }
        return `${host}${prefix}`;
    },
};

const client = Axios.create({
    baseURL: internals.getApiBase(),
    responseType: 'json',
});

export default client;

client.batch = (reqs) => {
    const { prefix } = internals;

    const requests = reqs.map((request) => ({
        ...request,
        path: `${prefix}${request.path}`,
    }));

    return client.post(internals.getApiBase(), { requests });
};

client.updateAuth = (newToken) => {
    const headers = client.defaults.headers.common;

    if (!newToken) {
        return delete headers.authorization;
    }

    headers.authorization = `Bearer ${newToken}`;

    return true;
};
