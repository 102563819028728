import { Suspense, lazy } from 'react';
import {
    Navigate,
    Route,
} from 'react-router-dom';

import PublicHeader from './header';
import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme';
import { SentryRoutes } from '../../utils/sentry';

const Login = lazy(() => import('../../routes/login'));
const ForgotPassword = lazy(() => import('../../routes/login/forgot-password'));
const ResetPassword = lazy(() => import('../../routes/login/reset-password'));
const Error404 = lazy(() => import('../../routes/error/404'));
const ErrorSentry = lazy(() => import('../../routes/error/sentry'));
const Homepage = lazy(() => import('../../routes/public/home'));
const Step1EnterInfo = lazy(() => import('../../routes/public/find-case/step1-enter-info'));
const Step2SelectCase = lazy(() => import('../../routes/public/find-case/step2-select-case'));
const Step3AgreeToTerms = lazy(() => import('../../routes/public/find-case/step3-agree-to-terms'));
const Step4Submitted = lazy(() => import('../../routes/public/find-case/step4-thanks'));
const Searching = lazy(() => import('../../routes/public/find-case/searching'));

const PublicLayout = () => (
    <Suspense fallback={<div />}>
        <PublicHeader />
        <main>
            <SentryRoutes>
                <Route path="/error/404" element={<Error404 />} />
                <Route path="/error/sentry-test" element={<ErrorSentry />} />
                <Route path="/login" element={<Login />} />
                <Route path="/login/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:resetToken" element={<ResetPassword />} />

                <Route path="/" exact element={<Homepage />} />
                {/* intake */}
                <Route path="/find-case/1" exact element={<Step1EnterInfo />} />
                <Route path="/find-case/2" exact element={<Step2SelectCase />} />
                <Route path="/find-case/3" exact element={<Step3AgreeToTerms />} />
                <Route path="/find-case/4" exact element={<Step4Submitted />} />
                <Route path="/find-case/searching" exact element={<Searching />} />

                {/* catchall */}
                <Route path="/*" element={<Navigate replace to="/error/404" />} />
            </SentryRoutes>
        </main>
    </Suspense>
);

export default withRoot(PublicLayout, themer);
