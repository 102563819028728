import { createTheme } from '@mui/material/styles';
import Colors from './colors';
// https://material-ui.com/customization/default-theme/?expend-path=$.palette.background
// https://fonts.adobe.com/fonts/ibm-plex-sans#fonts-section
const baseTheme = createTheme({
    spacing: 5,
    padding: 5,
    typography: {
        fontFamily: '"Inter", sans-serif',
        fontWeight: 400,
        color: '#000000',
        h1: {
            fontSize: '6rem',
            fontWeight: 600,
            lineHeight: 1.1,
            color: '#000000',
        },
        h2: {
            fontSize: '3rem',
            fontWeight: 500,
        },
        h3: {
            fontSize: '2.4rem',
            fontWeight: 700,
            color: Colors.deepPurple,
        },
        h4: {
            fontSize: '3.1rem',
            lineHeight: '3.72rem',
        },
        h5: {
            fontSize: '2.5rem',
            lineHeight: '3.0rem',
            fontWeight: 500,
        },
        h6: {
            fontSize: '2.0rem',
            lineHeight: '2.4rem',
            fontWeight: 'bold',
        },
        body1: {
            fontSize: '1.6rem',
            color: Colors.grey1,
        },
        body2: {
            fontSize: '1.5rem',
        },
        button: {
            textTransform: 'none',
        },
    },
    palette: {
        text: {
            primary: '#000000',
            secondary: Colors.grey1,
        },
        primary: {
            main: Colors.purple1,
            light: Colors.purple2,
            contrastText: '#FFFFFF',
        },
        /*
        secondary: {},
        tertiary: {},
        quaternary: {
            // main: Colors.tAndPink,
        },
        background: {
            // default: Colors.whiteDark,
        },
        */
        error: {
            main: Colors.error,
        },
        success: {
            main: Colors.success,
        },
    },
});
const theme = createTheme(baseTheme, {
    typography: {
        body2: {
            color: baseTheme.palette.text.secondary,
        },
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiFormControlLabel: { // checkboxes, radio buttons
            styleOverrides: {
                label: {
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    lineHeight: 0,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    top: 0,
                    left: 0,
                    color: baseTheme.palette.text.primary,
                    fontSize: '1.4rem',
                    fontWeight: 600,
                    '&.Mui-error, &.Mui-focused': {
                        color: baseTheme.palette.text.primary,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: '"Roboto", sans-serif',
                    fontSize: '1.6rem',
                },
                outlined: { /* this code takes the lable outside of the textfield */
                    position: 'relative',
                    top: -15,
                    left: -15,
                },
                filled: {
                    color: Colors.grey1,
                    fontWeight: 400,
                },
                shrink: {
                    '&.Mui-focused': {
                        color: baseTheme.palette.primary.main,
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '1.3rem',
                    marginLeft: 0,
                    marginRight: 0,
                    color: baseTheme.palette.text.secondary,
                    '&.Mui-error': {
                        color: Colors.error,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.text.primary,
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.purple4,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FFFFFF',
                    fontSize: '1.5rem',
                    marginTop: 5,

                    '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
                        // borderColor: Colors.blue,
                    },
                },
                notchedOutline: {
                    // borderColor: Colors.grey,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        // color: Colors.blue,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        // color: Colors.blue,
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.text.secondary,
                    lineHeight: 1.1875,
                    fontSize: '1.5rem',

                    '&[aria-selected=true]': {
                        color: baseTheme.palette.primary.contrastText,
                        backgroundColor: baseTheme.palette.primary.main,
                    },

                    '&[role=option]': { // select menu options
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 22,
                        paddingRight: 22,
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    boxShadow: '0px 10px 19px -4px rgba(0, 0, 0, 0.15)',
                    borderRadius: '0 0 8px 8px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    lineHeight: '2.1rem',
                    textTransform: 'none',
                    fontSize: '1.6rem',
                    padding: '16px',
                    '&:focus-visible': {
                        boxShadow: '0px 0px 5px rgba(0, 128, 255, 0.9)',
                    },
                },
                containedPrimary: {
                    borderRadius: 100,
                    '&:hover': {
                        backgroundColor: baseTheme.palette.primary.light,
                    },
                    '&.Mui-disabled': {
                        // backgroundColor: Colors.greyLightest,
                        // color: Colors.greyLight,
                    },
                },
                outlinedPrimary: {
                    borderRadius: 100,
                    // borderColor: Colors.greyLight,
                    '&:hover': {
                        // backgroundColor: Colors.greyLightest,
                        // borderColor: baseTheme.palette.primary.main,
                    },
                },
                containedSecondary: {
                    '&:hover': {
                        // backgroundColor: baseTheme.palette.secondary.light,
                    },
                },
                containedTertiary: {
                    '&:hover': {
                        // backgroundColor: baseTheme.palette.tertiary.light,
                    },
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                primary: {

                    '&.Mui-disabled, &[aria-disabled=true]': {
                        opacity: '40%',
                        backgroundColor: baseTheme.palette.primary.main,
                        color: baseTheme.palette.primary.contrastText,
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontSize: '1.5rem',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '1.5rem',
                    '&.Mui-selected': {
                        backgroundColor: baseTheme.palette.primary.main,
                        color: baseTheme.palette.primary.contrastText,
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                message: {
                    fontSize: '1.5rem',
                },
                root: {
                    color: baseTheme.palette.text.primary,
                    '& .MuiAlert-icon': {
                        color: baseTheme.palette.text.primary,
                    },
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    '& .MuiAlert-root': {
                        alignItems: 'center',

                        '& .MuiAlert-action': {
                            paddingTop: 0,
                        },
                    },
                },
            },
        },
    },
});

export default theme;
